import * as yup from 'yup'

export const validateFifteenDaysWithPeriods = async data => {
  const type = yup
    .array()
    .of(yup.number().nullable())
    .min(60)
    .max(60)
    .required()

  const schema = yup.object({
    periods: yup
      .array()
      .of(yup.string().nullable())
      .min(60)
      .max(60)
      .required(),
    points: yup.object({
      forecast: yup.object({
        precipitation: type,
        pressure: type,
        rel_humidity: type,
        temperature: type,
        wind_speed: type,
        wind_gust: type,
        wind_direction: type,
        weather_conditions: type
      })
    })
  })

  const isValid = await schema.isValid(data)

  if (!isValid) {
    const { message } = await schema.validate(data)

    throw new Error(message)
  }

  return data
}

export const validateFifteenDays = async data => {
  const type = yup
    .array()
    .of(yup.number().nullable())
    .min(15)
    .max(15)
    .required()

  const schema = yup.object({
    periods: yup
      .array()
      .of(yup.string().nullable())
      .min(15)
      .max(15)
      .required(),
    points: yup.object({
      forecast: yup.object({
        precipitation_daily_acu: type,
        pressure_daily_avg: type,
        rel_humidity_daily_avg: type,
        wind_speed_daily_avg: type,
        wind_direction_daily_avg: type,
        temperature_daily_avg: type,
        weather_conditions_daily_avg: type,
        rel_humidity_daily_min: type,
        rel_humidity_daily_max: type,
        temperature_daily_min: type,
        temperature_daily_max: type,
        pressure_daily_min: type,
        pressure_daily_max: type,
        wind_speed_daily_max: type,
        wind_gust_daily_max: type
      })
    })
  })

  const isValid = await schema.isValid(data)

  if (!isValid) {
    const { message } = await schema.validate(data)

    throw new Error(message)
  }

  return data
}
