import React from 'react'
import { useActions, useStore } from 'easy-peasy'
import { not, equals } from 'ramda'

import { dispatcher } from 'tbg/utils'
import { CAPTION } from 'tbg/config'
import { fetchByOrgan } from 'tbg/services'

import { Navigation as Nav } from '@somarmeteorologia/momentum'

const { useInterable } = Nav

export default function Interable({ children }) {
  const {
    openRadars,
    openSatellites,
    openForecast,
    openStations,
    openPipelines,
    openLightning,
    openFires,
    openRain,

    setLayer,

    setRadarsOpacity,
    setSatellitesOpacity,
    setForecastsOpacity,

    setAnimation,
    setForecast,
    setRadar,
    setStationsAsActive,
    setStations,
    setStationsType,

    setLocality,
    setForecastType,
    setReportsType,
    setLightningType,
    setReportsDates,

    setCaption,

    setFilters,

    setRecordsLocality
  } = useActions(
    ({
      radars,
      satellites,
      forecasts,
      pipelines,
      fires,
      rain,
      map,
      stations,
      organs,
      forecast,
      lightning,
      filters,
      records,
      reports
    }) => ({
      setStationsAsActive: organs.setAsActive,
      setStations: organs.setStations,
      setStationsType: organs.setType,

      openStations: stations.toOpen,
      openRadars: radars.toOpen,
      openForecast: forecasts.toOpen,
      openPipelines: pipelines.toOpen,
      openSatellites: satellites.toOpen,
      openLightning: lightning.toOpen,
      openFires: fires.toOpen,
      openRain: rain.toOpen,

      setSatellitesOpacity: satellites.setOpacity,
      setRadarsOpacity: radars.setOpacity,
      setForecastsOpacity: forecasts.setOpacity,
      setForecast: forecasts.set,
      setRadar: radars.set,
      setRadars: radars.persist,
      setLayer: pipelines.set,
      setCaption: map.setCaption,
      setAnimation: satellites.setAnimate,
      setFilters: filters.set,

      setLocality: forecast.setLocality,
      setForecastType: forecast.setType,
      setLightningType: lightning.setType,

      setRecordsLocality: records.setLocality,
      setReportsType: reports.setType,
      setReportsDates: reports.setDates
    })
  )

  const {
    isRadarsOpen,
    isSatellitesOpen,
    isPipelinesOpen,
    isForecastOpen,
    isStationsOpen,
    isLightningOpen,
    isFiresOpen,
    isRainOpen,

    radarsOpacity,
    satellitesOpacity,
    forecastsOpacity,

    isAnimated,
    radars,
    forecasts,
    organs,
    stationsType,
    forecastType,
    reportsType,
    reportsDates,
    lightningType,
    layers,
    locality,
    filters,
    recordsLocality,
    recordsType
  } = useStore(
    ({
      radars,
      satellites,
      forecasts,
      pipelines,
      stations,
      fires,
      rain,
      organs,
      forecast,
      config,
      lightning,
      filters,
      records,
      reports
    }) => ({
      radarsOpacity: radars.opacity * 100,
      satellitesOpacity: satellites.opacity * 100,
      forecastsOpacity: forecasts.opacity * 100,

      isForecastOpen: forecasts.isOpen,
      isSatellitesOpen: satellites.isOpen,
      isRadarsOpen: radars.isOpen,
      isPipelinesOpen: pipelines.isOpen,
      isStationsOpen: stations.isOpen,
      isLightningOpen: lightning.isOpen,
      isFiresOpen: fires.isOpen,
      isRainOpen: rain.isOpen,

      isAnimated: satellites.animate,
      radars: radars.data,
      forecasts: forecasts.layers,
      organs: organs.data,
      layers: pipelines.layers,
      stationsType: organs.type,
      reportsType: reports.type,
      lightningType: lightning.type,
      reportsDates: reports.dates,
      forecastType: forecast.type,
      locality: forecast.locality,
      filters: filters.data,

      page: config.page,

      recordsLocality: records.locality,
      recordsType: records.type
    })
  )

  const isAutocompleteVisibleWhen = ['period', 'daily', 'map']

  const isRecordsAutocompleteVisible = () => {
    const withAutocomplete = ['daily', 'monthly']

    return withAutocomplete.includes(recordsType)
  }

  const interable = [
    {
      id: 'satellite',
      interables: [
        {
          id: 'toggleSatellite',
          value: isSatellitesOpen
        }
      ]
    },
    {
      id: 'satelliteContent',
      interables: [
        {
          id: 'animated',
          value: isAnimated
        },
        {
          id: 'satellitesOpacity',
          value: satellitesOpacity
        }
      ]
    },
    {
      id: 'radars',
      interables: [
        {
          id: 'toggleRadars',
          value: isRadarsOpen
        }
      ]
    },
    {
      id: 'radarsContent',
      interables: [
        {
          id: 'radarsOpacity',
          value: radarsOpacity
        },
        ...radars.map(({ id, active }) => ({
          id,
          value: active
        }))
      ]
    },
    {
      id: 'forecasts',
      interables: [
        {
          id: 'toggleForecasts',
          value: isForecastOpen
        }
      ]
    },
    {
      id: 'forecastsContent',
      interables: [
        {
          id: 'forecastsOpacity',
          value: forecastsOpacity
        },
        ...forecasts.map(({ id, active }) => ({
          id,
          value: active
        }))
      ]
    },
    {
      id: 'stations',
      interables: [
        {
          id: 'toggleStations',
          value: isStationsOpen
        }
      ]
    },
    {
      id: 'stationsContent',
      interables: [
        {
          id: 'stationsType',
          value: stationsType
        },
        ...Object.values(organs).map(({ id, active }) => ({
          id,
          value: active
        }))
      ]
    },
    {
      id: 'lightning',
      interables: [
        {
          id: 'toggleLightning',
          value: isLightningOpen
        }
      ]
    },
    {
      id: 'lightningContent',
      interables: [
        {
          id: 'lightningType',
          value: lightningType
        }
      ]
    },
    {
      id: 'pipelines',
      interables: [
        {
          id: 'togglePipelines',
          value: isPipelinesOpen
        }
      ]
    },
    {
      id: 'pipelinesContent',
      interables: [
        ...layers.map(({ id, active }) => ({
          id,
          value: active
        }))
      ]
    },
    {
      id: 'fires',
      interables: [
        {
          id: 'toggleFires',
          value: isFiresOpen
        }
      ]
    },
    {
      id: 'rain',
      interables: [
        {
          id: 'toggleRain',
          value: isRainOpen
        }
      ]
    },
    {
      id: 'forecastContent',
      interables: [
        {
          id: 'locality',
          value: locality
        },
        {
          id: 'forecastType',
          value: forecastType
        },
        ...Object.values(filters).map(({ id, active }) => ({
          id,
          value: active
        })),
        {
          id: 'isAutocompleteVisible',
          value: false
        },
        {
          id: 'citiesAndPoints',
          value: []
        }
      ]
    },
    {
      id: 'recordsContent',
      interables: [
        {
          id: 'recordsLocality',
          value: recordsLocality
        },
        {
          id: 'recordsAutocomplete',
          value: isRecordsAutocompleteVisible()
        },
        {
          id: 'points',
          value: []
        }
      ]
    },
    {
      id: 'reportsContent',
      interables: [
        {
          id: 'reportsType',
          value: reportsType
        },
        {
          id: 'reportsDates',
          value: reportsDates
        }
      ]
    }
  ]

  const onInterable = ({ interables, setInterables }) => {
    const withInterable = useInterable({
      interables,
      setInterables
    })

    const get = array => id => {
      const recursively = index =>
        index.id === id ? index.value : get(index.interables)(id)
      return (
        array &&
        array
          .map(recursively)
          .flat()
          .filter(exists => not(equals(exists, undefined)))[0]
      )
    }

    const getById = get(interables)

    const [satellite] = withInterable('satellite')
    const [radar] = withInterable('radars')
    const [forecast] = withInterable('forecasts')
    const [setForecastContent] = withInterable('forecastContent')

    dispatcher([
      [
        getById('toggleSatellite'),
        isSatellitesOpen,
        open => {
          openSatellites(open)

          if (open) {
            setCaption(CAPTION.satellites)
            radar('toggleRadars', false)
            return forecast('toggleForecasts', false)
          }

          setCaption(null)
        }
      ],

      [getById('satellitesOpacity'), satellitesOpacity, setSatellitesOpacity],
      [getById('animated'), isAnimated, setAnimation],

      [
        getById('toggleRadars'),
        isRadarsOpen,
        open => {
          openRadars(open)

          if (open) {
            setCaption(CAPTION.radars)
            forecast('toggleForecasts', false)
            return satellite('toggleSatellite', false)
          }

          setCaption(null)
        }
      ],
      [
        getById('toggleFires'),
        isFiresOpen,
        open => {
          openFires(open)
          setCaption(null)
        }
      ],
      [
        getById('toggleRain'),
        isRainOpen,
        open => {
          openRain(open)
          setCaption(null)
        }
      ],
      [getById('radarsOpacity'), radarsOpacity, setRadarsOpacity],
      ...radars.map(({ id, active }) => [
        getById(id),
        active,
        value => setRadar({ id, value })
      ]),

      [
        getById('toggleForecasts'),
        isForecastOpen,
        open => {
          openForecast(open)

          if (open) {
            radar('toggleRadars', false)
            satellite('toggleSatellite', false)
          }

          setCaption(null)
        }
      ],
      [getById('forecastsOpacity'), forecastsOpacity, setForecastsOpacity],
      ...forecasts.map(({ id, active }) => [
        getById(id),
        active,
        value => {
          setForecast({ id, value })
          setCaption(null)

          value && setCaption(CAPTION[id])
        }
      ]),

      [getById('toggleStations'), isStationsOpen, openStations],
      [getById('stationsType'), stationsType, setStationsType],
      ...Object.values(organs).map(({ id, active }) => [
        getById(id),
        active,
        value => {
          setStationsAsActive({ id, value })

          value && fetchByOrgan(id).then(data => setStations({ id, data }))
        }
      ]),

      [getById('togglePipelines'), isPipelinesOpen, openPipelines],
      ...layers.map(({ id, active }) => [
        getById(id),
        active,
        value => setLayer({ id, value })
      ]),

      ...Object.values(filters).map(({ id, active }) => [
        getById(id),
        active,
        value => setFilters({ id, value })
      ]),

      [getById('locality'), locality, setLocality],
      [getById('toggleLightning'), isLightningOpen, openLightning],
      [getById('lightningType'), lightningType, setLightningType],
      [getById('toggleFires'), isFiresOpen, openFires],
      [getById('toggleRain'), isRainOpen, openRain],
      [getById('forecastType'), forecastType, setForecastType],
      [
        getById('forecastType'),
        forecastType,
        type => {
          setForecastContent(
            'isAutocompleteVisible',
            isAutocompleteVisibleWhen.includes(type)
          )
        }
      ],
      [getById('reportsType'), reportsType, setReportsType],
      [getById('reportsDates'), reportsDates, setReportsDates],

      [getById('recordsLocality'), recordsLocality, setRecordsLocality]
    ])
  }

  return <>{children({ interable, onInterable })}</>
}
