import React, { useRef, useEffect, useState } from 'react'
import { renderToString } from 'react-dom/server'
import Websocket from 'react-websocket'
import L from 'leaflet'
import v4 from 'uuid/v4'
import { Marker as Markerable } from 'react-leaflet'
import moment from 'moment'
import PropTypes from 'prop-types'
import useInterval from '@use-it/interval'
import Markers from '@somarmeteorologia/react-leaflet-markers'

import { Env } from 'tbg/environment'
import { Snackbar, Text } from '@somarmeteorologia/momentum'

const URL = Env.getEnv(Env.WS_URL)

export function Streamable({ isOpen, marker, channel, messages, setMessages }) {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const toClose = () => setIsSnackbarOpen(false)

  const ref = useRef(null)

  const connect = () => {
    const command = { action: 'subscribe', channel }
    ref.current.sendMessage(JSON.stringify(command))
  }

  const onMessage = message => {
    const { data } = JSON.parse(message)

    setMessages(messages => [
      ...messages,
      ...data.map(value => ({
        ...value,
        time: moment().toString()
      }))
    ])
  }

  const defaultIcon = L.icon({
    html: renderToString(marker),
    iconSize: [15, 15]
  })

  useInterval(
    () => {
      const now = moment()

      const filtered = messages =>
        messages.filter(message => {
          const duration = moment.duration(now.diff(message.time))

          return duration.minutes() >= 5 ? false : true
        })

      setMessages(messages => filtered(messages))
    },
    isOpen ? 5000 : null
  )

  useEffect(() => {
    !isOpen && setMessages([])
    setIsSnackbarOpen(!messages.length)
  }, [isOpen, messages.length])

  const markers = () => {
    return messages.map(message => {
      const key = v4()

      return (
        <Markerable
          key={key}
          icon={defaultIcon}
          position={[message.latitude, message.longitude]}
          properties={{ ...message, key }}
        />
      )
    })
  }

  return (
    <>
      {!!messages.length && (
        <Markers dataKey="properties" isDebug={false} markers={markers()} />
      )}

      <Snackbar
        data-testid="snackbar"
        duration={3}
        isOpen={isSnackbarOpen}
        toClose={toClose}
        description={({ size, color }) => (
          <Text size={size} color={color}>
            Sem incidência de descargas elétricas na área da Ecorodovias neste momento.
          </Text>
        )}
      />

      <Websocket
        ref={ref}
        url={URL}
        reconnect={true}
        debug={true}
        onOpen={connect}
        onMessage={onMessage}
      />
    </>
  )
}

Streamable.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  channel: PropTypes.oneOf(['lightnings-tbg', 'lightnings-tbg-2h']).isRequired,
  messages: PropTypes.array.isRequired,
  setMessages: PropTypes.func.isRequired,
  marker: PropTypes.node.isRequired
}
