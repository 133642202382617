import React, { useContext, useState } from 'react'
import styled, { ThemeContext, css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import posed from 'react-pose'
import PropTypes from 'prop-types'

import { Navigable } from 'tbg/containers'
import { Footer } from 'tbg/components'

import { Text } from '@somarmeteorologia/momentum'

import logo from 'tbg/resources/logo.svg'

const Container = styled.div`
  height: 100vh;
  z-index: ${theme('zindex.drawer')};
  box-shadow: ${theme('navigation.shadow.primary')};
`

const Organization = posed(styled.div`
  background-color: ${theme('navigation.bg.primary')};
  height: 90px;
  display: flex;
  align-items: center;

  ${ifProp(
    { toggled: false },
    css`
      padding: 0 15px;
    `
  )}
`)({
  visible: {
    width: 270
  },
  collapsed: {
    width: 10
  }
})

const Logo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export function Sidebar({ toOpenToast }) {
  const [toggled, setToggled] = useState(false)
  const { animations, text } = useContext(ThemeContext)

  return (
    <Container>
      <Organization
        toggled={toggled}
        initialPose={animations.visible}
        pose={toggled ? animations.collapsed : animations.visible}
      >
        {!toggled && (
          <>
            <Logo>
              <img src={logo} alt="Ecorodovias" />
            </Logo>
          </>
        )}
      </Organization>

      <Navigable toOpenToast={toOpenToast} setToggled={setToggled} />

      <Footer
        toggled={toggled}
        initialPose={animations.visible}
        pose={toggled ? animations.collapsed : animations.visible}
      />
    </Container>
  )
}

Sidebar.propTypes = {
  toOpenToast: PropTypes.func.isRequired
}
