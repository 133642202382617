import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useStore } from 'easy-peasy'
import { withRouter } from 'react-router-dom'
import { theme } from 'styled-tools'

import { Icon, Text } from '@somarmeteorologia/momentum'

const Container = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  background-color: ${theme('colors.white.hundred')};

  .back {
    margin-top: 3px;
    margin-right: 5px;
  }
`

export const Navigation = withRouter(({ history }) => {
  const { text } = useContext(ThemeContext)
  const { page, tab } = useStore(({ config, tab }) => ({
    page: config.page,
    tab: tab.value
  }))

  const getTab = () => {
    const mapper = {
      period: 'Por período (3 dias)',
      map: 'Avisos 72 horas (Mapas)',
      hourly: 'Avisos 72 horas',
      reports: 'Últimos boletins',
      hours: 'Horários',
      dailys: 'Diários',
      months: 'Mensais'
    }

    return mapper[tab]
  }

  const hasTab = () => ['forecast', 'reports', 'records'].includes(page.id)

  return (
    <Container>
      <Icon
        className="back"
        name="arrowRight"
        onClick={() => history.push('/')}
        color={text.tertiary}
        width={18}
        height={18}
      />

      <Text.Heading
        left={10}
        size={Text.Heading.size.twentyOne}
        color={text.quaternary}
      >
        {page.title}
      </Text.Heading>

      {hasTab() && (
        <>
          <Text left={15} size={Text.size.fourteen} color={text.quaternary}>
            |
          </Text>

          <Text left={15} size={Text.size.fourteen} weight={Text.weight.bold}>
            {getTab()}
          </Text>
        </>
      )}
    </Container>
  )
})
