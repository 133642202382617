import { toJSON, zipWith } from './utils'
import { Env } from 'tbg/environment'
import { range } from 'ramda'

const URI = `${Env.getEnv(Env.BROLY_URI)}/portal/reports`

const headersConfig = {
  headers: {
    'x-api-key': Env.getEnv(Env.BROLY_KEY)
  }
}

const normalize = data => {
  const { dates, titles, types, urls } = data

  return zipWith(
    (date, title, type, url) => {
      return Object.assign(
        {},
        {
          date,
          title,
          type,
          url
        }
      )
    },
    dates,
    titles,
    types,
    urls
  )
}

const getUrlByMonthAndDates = (month, dateRage) => {
  return dateRage.map(d => {
    const day = ('0' + d).slice(-2)
    return {
      date: `2020-${month}-${day}`,
      url: `https://s3.amazonaws.com/boletins.ecovias/2020/${month}/${day}/boletim_Ecovias.pdf`
    }
  })
}

const mockReports = async () => {
  const november = getUrlByMonthAndDates(11, range(20, 31))
  const december = getUrlByMonthAndDates(12, range(1, 3))

  return [...december, ...november].sort((a,b) => new Date(b.date) - new Date(a.date))
}

export function fetchReports({ from, to }) {
  return mockReports()
  // return fetch(`${URI}?init_date=${from}&end_date=${to}`, headersConfig)
  //   .then(toJSON)
  //   .then(normalize)
}
