import { toJSON } from './utils'
import { Env } from 'tbg/environment'
import { ECOVIAS_STATIONS } from 'tbg/config'
import { random } from '../utils'

const URI = `${Env.getEnv(Env.BROLY_URI)}/stations`

const headersConfig = {
  headers: {
    'x-api-key': Env.getEnv(Env.BROLY_KEY)
  }
}

const normalize = data => {
  const { locations, points } = data

  const { station_name, station_id, latitude, longitude } = locations
  const {
    wind_speed,
    air_temperature,
    precipitation_amount,
    relative_humidity
  } = points

  return station_name.map((name, index) => ({
    name,
    id: station_id[index],
    latitude: latitude[index],
    longitude: longitude[index],
    wind: wind_speed && wind_speed[index] ? wind_speed[index] : null,
    precipitation:
      precipitation_amount && !isNaN(precipitation_amount[index])
        ? precipitation_amount[index]
        : null,
    temperature:
      air_temperature && air_temperature[index] ? air_temperature[index] : null,
    humidity:
      relative_humidity && relative_humidity[index]
        ? relative_humidity[index]
        : null
  }))
}

function fetchMockedEcovias() {
  return new Promise((resolve, reject) => {
    resolve(ECOVIAS_STATIONS.map((ecoviasStationData) => ({
        id: 'ecovias',
        name: ecoviasStationData['stationName'],
        latitude: ecoviasStationData['latitude'],
        longitude: ecoviasStationData['longitude'],
        wind : random(0, 50),
        precipitation: random(0, 10),
        temperature: random(20, 35),
        humidity: random(80, 100)
    })))
  })
}


export function fetchByOrgan(organ) {
  if (organ === 'ecovias') {
    return fetchMockedEcovias()
  }

  return fetch(`${URI}/${organ}`, headersConfig)
  .then(toJSON)
  .then(normalize)
}

