import moment from 'moment'
import { pipe, map, zipWith, toString, keys, values, slice } from 'ramda'

import {
  degToCompass,
  slicer,
  toFixed,
  toPeriod,
  toInitial,
  toDescription,
  groupByDayAndMonth
} from './utils'

export const normalizeMonthlyRecords = ({ meta, points }) => ({
  updated_at: meta.updated_at,
  max_temperature: points.observed.monthly_max_temperature[0].toFixed(1),
  min_temperature: points.observed.monthly_min_temperature[0].toFixed(1),
  thermal_amplitude: points.observed.monthly_thermal_amplitude[0].toFixed(1),
  accumulated_precipitation: points.observed.monthly_accumulated_precipitation[0].toFixed(
    1
  )
})

export const normalizePeriodly = ({ periods, points }) => {
  const { forecast } = points
  const dates = slicer(periods)

  const {
    precipitation,
    pressure,
    rel_humidity,
    wind_speed,
    wind_direction,
    temperature,
    weather_conditions
  } = forecast

  const normalized = normalize({
    precipitation,
    pressure,
    humidity: rel_humidity,
    windSpeed: wind_speed,
    windDirection: wind_direction,
    temperature,
    weatherCondition: weather_conditions,
    periods
  })

  const structured = dates.map((date, index) => ({
    date: moment(date),
    rain: normalized.precipitation[index],
    pressure: normalized.pressure[index],
    period: toPeriod(moment(date).format('HH')),
    condition: {
      initial: toInitial(
        normalized.weatherCondition[index],
        moment(date).format('HH')
      ),
      description: toDescription(normalized.weatherCondition[index])
    },
    wind: {
      direction: normalized.windDirection[index],
      speed: normalized.windSpeed[index]
    },
    humidity: normalized.humidity[index],
    temperature: normalized.temperature[index]
  }))

  try {
    return {
      chart: dates.map((date, index) => ({
        date,
        temperature: normalized.temperature[index],
        rain: normalized.precipitation[index]
      })),
      table: zipWith(
        (key, value) => Object.assign({}, { date: key, dates: value }),
        keys(groupByDayAndMonth(structured)),
        values(groupByDayAndMonth(structured))
      )
    }
  } catch (error) {
    throw new Error(error)
  }
}

export const normalizeDaily = ({ periods, points }) => {
  const { forecast } = points
  const dates = slicer(periods)

  const {
    precipitation_daily_acu,
    pressure_daily_avg,
    rel_humidity_daily_avg,
    wind_speed_daily_avg,
    wind_direction_daily_avg,
    temperature_daily_avg,
    weather_conditions_daily_avg,

    rel_humidity_daily_min,
    rel_humidity_daily_max,
    temperature_daily_min,
    temperature_daily_max,
    pressure_daily_min,
    pressure_daily_max,
    wind_speed_daily_max,
    wind_gust_daily_max
  } = forecast

  const normalized = {
    ...normalize({
      precipitation: precipitation_daily_acu,
      pressure: pressure_daily_avg,
      humidity: rel_humidity_daily_avg,
      windSpeed: wind_speed_daily_avg,
      windDirection: wind_direction_daily_avg,
      temperature: temperature_daily_avg,
      weatherCondition: weather_conditions_daily_avg
    }),
    minHumidity: pipe(
      slicer,
      map(parseInt)
    )(rel_humidity_daily_min),
    maxHumidity: pipe(
      slicer,
      map(parseInt)
    )(rel_humidity_daily_max),
    minTemperature: pipe(
      slicer,
      map(parseInt)
    )(temperature_daily_min),
    maxTemperature: pipe(
      slicer,
      map(parseInt)
    )(temperature_daily_max),
    maxWindSpeed: pipe(
      slicer,
      map(Math.round)
    )(wind_speed_daily_max),
    maxWindDirection: pipe(
      slicer,
      map(Math.round)
    )(wind_gust_daily_max),
    minPressure: pipe(
      slicer,
      map(
        pipe(
          toString,
          slice(0, 4),
          parseInt
        )
      )
    )(pressure_daily_min),
    maxPressure: pipe(
      slicer,
      map(
        pipe(
          toString,
          slice(0, 4),
          parseInt
        )
      )
    )(pressure_daily_max)
  }

  const structured = dates.map((date, index) => ({
    date: moment(date),
    rain: normalized.precipitation[index],
    pressure: normalized.pressure[index],
    period: toPeriod(moment(date).format('HH')),
    condition: {
      initial: toInitial(
        normalized.weatherCondition[index],
        moment(date).format('HH')
      ),
      description: toDescription(normalized.weatherCondition[index])
    },
    wind: {
      maxDirection: normalized.maxWindDirection[index],
      maxSpeed: normalized.maxWindSpeed[index],
      direction: normalized.windDirection[index],
      speed: normalized.windSpeed[index]
    },
    humidity: normalized.humidity[index],
    minHumidity: normalized.minHumidity[index],
    minPressure: normalized.minPressure[index],
    maxPressure: normalized.maxPressure[index],
    maxHumidity: normalized.maxHumidity[index],
    minTemperature: normalized.minTemperature[index],
    maxTemperature: normalized.maxTemperature[index],
    temperature: normalized.temperature[index]
  }))

  try {
    return {
      chart: dates.map((date, index) => ({
        date,
        minTemperature: normalized.minTemperature[index],
        maxTemperature: normalized.maxTemperature[index],
        temperature: normalized.temperature[index],
        rain: normalized.precipitation[index]
      })),
      table: zipWith(
        (key, value) => Object.assign({}, { date: key, dates: value }),
        keys(groupByDayAndMonth(structured)),
        values(groupByDayAndMonth(structured))
      )
    }
  } catch (error) {
    throw new Error(error)
  }
}

const normalize = ({
  precipitation,
  pressure,
  humidity,
  windSpeed,
  windDirection,
  temperature,
  weatherCondition
}) => ({
  temperature: map(parseInt, temperature),
  precipitation: map(toFixed, precipitation),
  weatherCondition,
  humidity: pipe(
    slicer,
    map(parseInt)
  )(humidity),
  pressure: pipe(
    slicer,
    map(
      pipe(
        toString,
        slice(0, 4),
        parseInt
      )
    )
  )(pressure),
  windSpeed: pipe(
    slicer,
    map(Math.round)
  )(windSpeed),
  windDirection: pipe(
    slicer,
    map(degToCompass)
  )(windDirection)
})
