import { toJSON } from './utils'
import { Env } from 'tbg/environment'

const URI = Env.getEnv(Env.IMAGERY_URL)

const headersConfig = {
  headers: {
    'x-api-key': Env.getEnv(Env.IMAGERY_KEY)
  }
}

const regions = {
  SUL: ['pr', 'rs', 'sc'],
  SUDESTE: ['sp', 'rj', 'es', 'mg'],
  'CENTRO-OESTE': ['mt', 'ms', 'go', 'df'],
  NORDESTE: ['ba', 'al', 'se', 'pb', 'pe', 'rn', 'ce', 'pi', 'ma'],
  NORTE: ['am', 'rr', 'ap', 'pa', 'to', 'ro', 'ac']
}

const getRegion = state =>
  Object.keys(regions).find(region =>
    regions[region].includes(state) ? region : null
  )

const addProperties = radars =>
  radars.map(radar => {
    const region = getRegion(radar.state)

    return {
      ...radar,
      region,
      active: region !== 'NORTE' && region !== 'NORDESTE' ? true : false
    }
  })

export function fetchRadars() {
  return fetch(`${URI}/radars`, headersConfig)
    .then(toJSON)
    .then(addProperties)
}
