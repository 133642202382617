export const MODULE = {
  monitoring: {
    id: 'monitoring',
    title: 'Monitoramento'
  }
}

export const PAGE = {
  map: {
    id: 'map',
    title: 'Mapa',
    path: '/'
  },
  reports: {
    id: 'reports',
    title: 'Boletins',
    path: '/reports'
  },
  forecast: {
    id: 'forecast',
    title: 'Previsões Pontuais',
    path: '/forecasts'
  },
  records: {
    id: 'records',
    title: 'Registros Históricos',
    path: '/records'
  }
}

export const LAYER = {
  mono: {
    id: 'mono',
    value: 'light_all'
  },
  color: {
    id: 'color',
    value: 'voyager_labels_under'
  },
  satellite: {
    id: 'satellite',
    value: 'cjxko1zlo04np1cmwe632wh35'
  },
  dark: {
    id: 'dark',
    value: 'dark_all'
  }
}

export const LAYERS = [
  {
    id: 'precacum',
    name: 'Precipitação Acumulada',
    info: null,
    active: false
  },
  {
    id: 't2m',
    name: 'Temperatura',
    info: null,
    active: false
  },
  {
    id: 'uv10',
    name: 'Vento',
    info: null,
    active: false
  }
]

export const FILTERS = {
  SP041PLANALTO: {
    id: 'SP041PLANALTO',
    name: 'SP-041-PLANALTO',
    active: true
  },
  SP055BAIXADA: {
    id: 'SP055BAIXADA',
    name: 'SP-055-BAIXADA',
    active: true
  },
  SP150PLANALTO: {
    id: 'SP150PLANALTO',
    name: 'SP-150-PLANALTO',
    active: true
  },
  SP150SERRA: {
    id: 'SP150SERRA',
    name: 'SP-150-SERRA',
    active: true
  },
  SP150BAIXADA: {
    id: 'SP150BAIXADA',
    name: 'SP-150-BAIXADA',
    active: true
  },
  SP160PLANALTO: {
    id: 'SP160PLANALTO',
    name: 'SP-160-PLANALTO',
    active: true
  },
  SP160SERRA: {
    id: 'SP160SERRA',
    name: 'SP-160-SERRA',
    active: true
  },
  SP160BAIXADA: {
    id: 'SP160BAIXADA',
    name: 'SP-160-BAIXADA',
    active: true
  },
  SP248BAIXADA: {
    id: 'SP248BAIXADA',
    name: 'SP-248-BAIXADA',
    active: true
  }
}

export const PIPELINES = [
  {
    id: 'guideline',
    name: 'Diretriz',
    active: true
  },
  {
    id: 'sp041Planalto',
    name: 'SP-041-PLANALTO',
    active: false
  },
  {
    id: 'sp055Baixada',
    name: 'SP-055-BAIXADA',
    active: false
  },
  {
    id: 'sp150Planalto',
    name: 'SP-150-PLANALTO',
    active: false
  },
  {
    id: 'sp150Serra',
    name: 'SP-150-SERRA',
    active: false
  },
  {
    id: 'sp150Baixada',
    name: 'SP-150-BAIXADA',
    active: false
  },

  {
    id: 'sp160Planalto',
    name: 'SP-160-PLANALTO',
    active: false
  },
  {
    id: 'sp160Serra',
    name: 'SP-160-SERRA',
    active: false
  },
  {
    id: 'sp160Baixada',
    name: 'SP-160-BAIXADA',
    active: false
  },
  {
    id: 'sp248Baixada',
    name: 'SP-248-BAIXADA',
    active: false
  }
]

export const CAPTION = {
  radars: 'radars',
  satellites: 'satellites',
  indcape: 'indcape',
  indswt: 'indswt',
  indtt: 'indtt',
  indk: 'indk',
  precacum: 'precacum',
  rh: 'rh',
  t2m: 't2m',
  uv10: 'uv10'
}

export const ORGANS = {
  ecovias: {
    id: 'ecovias',
    label: 'ECOVIAS',
    active: true
  },
  inmet: {
    id: 'inmet',
    label: 'INMET',
    active: false
  },
  cemaden: {
    id: 'cemaden',
    label: 'CEMADEN',
    active: false
  },
  redemet: {
    id: 'redemet',
    label: 'REDEMET',
    active: false
  }
}

export const TYPES = {
  wind: {
    id: 'wind'
  },
  precipitation: {
    id: 'precipitation'
  },
  temperature: {
    id: 'temperature'
  },
  humidity: {
    id: 'humidity'
  }
}

export const TABS = {
  period: 'period',
  daily: 'daily',
  map: 'map',
  hourly: 'hourly',
  reports: 'reports',
  hours: 'hours',
  dailys: 'dailys',
  months: 'months'
}

export const REPORTS_TYPES = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly'
}

export const ECOVIAS_STATIONS = [
  {
     'altitude':799.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.660118,
     'longitude':-46.633783,
     'stationName':'SCA KM 012+100 SUL SP 160'
  },
  {
     'altitude':735.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.874766,
     'longitude':-46.512322,
     'stationName':'SCA KM 003+000 LESTE SPI 040/150'
  },
  {
     'altitude':18.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.900357,
     'longitude':-46.427547,
     'stationName':'SCA KM 054+000 NORTE SP 150'
  },
  {
     'altitude':404.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.896894,
     'longitude':-46.507947,
     'stationName':'Pluviometro KM 051+200 SUL SP 160'
  },
  {
     'altitude':80.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.870195,
     'longitude':-46.331619,
     'stationName':'Pluviometro KM 254+000 OESTE SP 055'
  },
  {
     'altitude':7.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.93889,
     'longitude':-46.463437,
     'stationName':'Pluviometro KM 279+300 OESTE SP 055'
  },
  {
     'altitude':770.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.784768,
     'longitude':-46.596914,
     'stationName':'Pluviometro KM 028+500 NORTE SP 160'
  },
  {
     'altitude':756.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.753671,
     'longitude':-46.594053,
     'stationName':'SCA KM 024+500 NORTE SP 160'
  },
  {
     'altitude':792.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.821254,
     'longitude':-46.583398,
     'stationName':'SCA KM 032+400 SUL SP 160'
  },
  {
     'altitude':752.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.89394,
     'longitude':-46.563775,
     'stationName':'SCA KM 040+650 SUL SP 160'
  },
  {
     'altitude':592.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.924948,
     'longitude':-46.538577,
     'stationName':'SCA KM 046+200 SUL SP 160'
  },
  {
     'altitude':754.0,
     'lastUpdate':'Fri, 30 Nov 2018 09:00:00 GMT',
     'latitude':-23.855484,
     'longitude':-46.503825,
     'stationName':'SCA KM 038+800 NORTE SP 150'
  },
  {
     'altitude':751.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.863978,
     'longitude':-46.497914,
     'stationName':'SCA KM 001+000 LESTE SPI 040/150'
  },
  {
     'altitude':494.0,
     'lastUpdate':'Wed, 02 Dec 2020 12:00:00 GMT',
     'latitude':-23.885504,
     'longitude':-46.478673
  }
]
