import React, { useState, useContext, useEffect } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { TileLayer } from 'react-leaflet'
import PropTypes from 'prop-types'
import { theme, prop, switchProp, ifProp } from 'styled-tools'
import { useStore } from 'easy-peasy'
import posed, { PoseGroup } from 'react-pose'

import { Icon, Text } from '@somarmeteorologia/momentum'

import { LAYER, PAGE } from 'tbg/config'

const Button = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${ifProp(
    { isOpen: true },
    theme('colors.blue.fifty'),
    theme('colors.white.hundred')
  )};
  border-radius: ${theme('border.radius.fifty')};
  cursor: pointer;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.1s linear;

  &:hover {
    background-color: ${theme('colors.blue.fifty')};
  }
`

const Container = styled.div`
  left: 17px;
  bottom: 26px;
  position: absolute;
  z-index: ${theme('zindex.above')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Chooser = styled.div`
  width: 35px;
  height: 35px;
  border-radius: ${theme('border.radius.fifty')};

  ${ifProp(
    { isActive: true },
    css`
      border: 2px solid ${prop('color')};
    `
  )}

  ${switchProp('type', {
    color: css`
      background: linear-gradient(
        90deg,
        ${theme('colors.yellow.fifty')} 50%,
        ${theme('colors.blue.fifty')} 50%
      );
    `,
    dark: css`
      background: linear-gradient(
        90deg,
        ${theme('colors.gray.fifty')} 50%,
        ${theme('colors.ebony.zero')} 50%
      );
    `,
    mono: css`
      background: linear-gradient(
        90deg,
        ${theme('colors.white.hundred')} 50%,
        ${theme('colors.ebony.sixty')} 50%
      );
    `,
    satellite: css`
      background: linear-gradient(
        90deg,
        ${theme('colors.ebony.zero')} 50%,
        ${theme('colors.green.twenty')} 50%
      );
    `
  })}
`

const Content = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: ${prop('left')}px;
`

const Name = styled(Text)`
  opacity: ${ifProp({ isHover: true }, 1, 0)};
  text-transform: capitalize;
`

const Choose = ({ name, type, onClick, activeLayer, innerRef }) => {
  const { colors } = useContext(ThemeContext)
  const [isHover, setIsHover] = useState(false)

  const toggle = () => setIsHover(!isHover)

  const isActive = activeLayer.id === name

  const getColor = {
    mono: colors.ebony.zero,
    color: colors.ebony.zero,
    satellite: colors.white.hundred,
    dark: colors.white.hundred
  }

  return (
    <Content ref={innerRef} onClick={onClick}>
      <Name
        bottom={5}
        isHover={isHover}
        size={Text.size.twelve}
        color={getColor[activeLayer.id]}
      >
        {name}
      </Name>
      <Chooser
        type={type}
        onMouseEnter={toggle}
        onMouseLeave={toggle}
        color={getColor[activeLayer.id]}
        isActive={isActive}
      />
    </Content>
  )
}

Choose.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['mono', 'color', 'satellite', 'dark']).isRequired,
  onClick: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
  activeLayer: PropTypes.shape({
    id: PropTypes.oneOf(['mono', 'color', 'satellite', 'dark']).isRequired,
    value: PropTypes.string.isRequired
  })
}

export function ChooseLayer() {
  const { colors } = useContext(ThemeContext)
  const [isOpen, setIsOpen] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [activeLayer, setActiveLayer] = useState(LAYER.mono)

  const buttons = ['color', 'dark', 'mono', 'satellite']

  const { page } = useStore(({ config }) => ({
    page: config.page
  }))

  const toggle = () => setIsOpen(!isOpen)

  const choose = color => () => {
    toggle()

    setActiveLayer(LAYER[color])
  }

  useEffect(() => {
    page === PAGE.forecast
      ? setActiveLayer(LAYER.dark)
      : setActiveLayer(LAYER.mono)
  }, [page])

  const Forwarded = React.forwardRef((props, ref) => (
    <Choose innerRef={ref} {...props} />
  ))

  const ChoosePos = posed(Forwarded)({
    enter: {
      x: ({ id }) => 43 + id * 8,
      transition: {
        type: 'tween',
        duration: 300,
        ease: [0.64, 0.04, 0.35, 1]
      }
    },
    exit: {
      x: ({ id }) => -(id * 35 + 2),
      transition: {
        type: 'tween',
        duration: 300,
        ease: [0.64, 0.04, 0.35, 1]
      }
    }
  })

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url={activeLayer.id === 'satellite' 
          ? `https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}`
          : `https://{s}.basemaps.cartocdn.com/rastertiles/${activeLayer.value}/{z}/{x}/{y}.png`
        }
      />
      <Container>
        <Button
          onClick={toggle}
          isOpen={isOpen}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isOpen ? (
            <Icon
              name={Icon.name.close}
              width={12}
              height={12}
              color={colors.white.hundred}
            />
          ) : (
            <Icon
              width={20}
              name={Icon.name.layer}
              height={20}
              color={isHover ? colors.white.hundred : colors.ebony.zero}
            />
          )}
        </Button>

        <PoseGroup>
          {isOpen &&
            buttons.map((button, index) => (
              <ChoosePos
                id={index}
                key={index}
                name={button}
                type={button}
                activeLayer={activeLayer}
                onClick={choose(button)}
              />
            ))}
        </PoseGroup>
      </Container>
    </>
  )
}
