import React, { useContext } from 'react'
import v4 from 'uuid/v4'
import { useStore } from 'easy-peasy'
import { groupBy } from 'ramda'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'

import {
  Icon,
  Switch,
  Text,
  Navigation as Navigable,
  Range,
  Radio,
  Checkbox,
  Autocomplete,
  Tooltip,
  DatePicker,
  Box
} from '@somarmeteorologia/momentum'

const { Type, Interable, Title, Control, Separator } = Navigable
const categories = [
  {
    id: 'SP041PLANALTO',
    text: 'SP-041-PLANALTO',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  },

  {
    id: 'SP150BAIXADA',
    text: 'SP-150-BAIXADA',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  },

  {
    id: 'SP150SERRA',
    text: 'SP-150-SERRA',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  },

  {
    id: 'SP160PLANALTO',
    text: 'SP-160-PLANALTO',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  },

  {
    id: 'SP248BAIXADA',
    text: 'SP-248-BAIXADA',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  },

  {
    id: 'SP055BAIXADA',
    text: 'SP-055-BAIXADA',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  },

  {
    id: 'SP150PLANALTO',
    text: 'SP-150-PLANALTO',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  },

  {
    id: 'SP160BAIXADA',
    text: 'SP-160-BAIXADA',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  },

  {
    id: 'SP160SERRA',
    text: 'SP-160-SERRA',
    selected: true,
    icon: ({ width, height, color }) => (
      <Icon name="segment" width={width} height={height} color={color} />
    )
  },
]

const fullyCategories = [
  ...categories,
  {
    id: 'city',
    text: 'Cidades',
    selected: false,
    icon: ({ width, height, color }) => (
      <Icon name="city" width={width} height={height} color={color} />
    )
  }
]

const groupByRegion = groupBy(radar => radar.region)

const Container = styled(Box)`
  display: flex;
  align-items: end;
`

const Structure = ({ toOpenToast, children, history }) => {
  const { text } = useContext(ThemeContext)

  const { forecasts, organs, layers, filters, radars } = useStore(
    ({ radars, forecasts, pipelines, organs, config, filters }) => ({
      toOpen: config.toOpen,
      radars: radars.data,
      forecasts: forecasts.layers,
      organs: organs.data,
      layers: pipelines.layers,
      filters: filters.data
    })
  )

  const groupedRadars = groupByRegion(radars)
  const regions = Object.keys(groupedRadars).sort((a, b) => (a < b ? 1 : -1))

  const structure = [
    {
      id: 'monitoring',
      type: Type.Category,
      title: ({ details, description }) => (
        <>
          <Icon name="home" right={10} width={20} height={20} color={details} />
          <Text
            weight={Text.weight.bold}
            size={Text.size.fourteen}
            color={description}
          >
            Monitoramento
          </Text>
        </>
      ),
      children: [
        {
          id: 'pipelines',
          parent: 'monitoring',
          prevent: ({ getter }) => !getter('togglePipelines'),
          onPrevent: () => toOpenToast(),
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="togglePipelines"
                  label="Meus Locais"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('togglePipelines')}
                  onChange={() =>
                    setter('togglePipelines', !getter('togglePipelines'))
                  }
                />
              </>
            </Interable>
          ),
          type: Type.Group,
          children: [
            {
              id: 'pipelinesContent',
              type: Type.Item,
              parent: 'pipelines',
              title: ({ getter, setter }) => (
                <>
                  {layers.map(({ id, name }) => (
                    <Checkbox
                      key={v4()}
                      id={id}
                      labelAlign={Checkbox.labelAlign.right}
                      size={Checkbox.size.small}
                      onChange={value => setter(id, value)}
                      checked={getter(id)}
                      label={name}
                      bottom={10}
                    />
                  ))}
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'nowLabel',
          parent: 'monitoring',
          title: () => (
            <Text
              weight={Text.weight.bold}
              size={Text.size.ten}
              color={text.quaternary}
            >
              AGORA
            </Text>
          ),
          type: Type.Item,
          children: []
        },
        {
          id: 'stations',
          parent: 'monitoring',
          prevent: ({ getter }) => !getter('toggleStations'),
          onPrevent: () => toOpenToast(),
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="toggleStations"
                  label="Estações Meteorológicas"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('toggleStations')}
                  onChange={() =>
                    setter('toggleStations', !getter('toggleStations'))
                  }
                />
              </>
            </Interable>
          ),
          type: Type.Group,
          children: [
            {
              id: 'stationsContent',
              type: Type.Item,
              parent: 'stations',
              title: ({ getter, setter }) => (
                <>
                  <Control>
                    <Radio
                      name="type"
                      labelAlign={Radio.labelAlign.right}
                      size={Radio.size.small}
                      orientation={Radio.orientation.column}
                      onChange={value => setter('stationsType', value)}
                    >
                      <Radio.Option
                        id="wind"
                        label="Velocidade do Vento (km/h)"
                        bottom={10}
                      />
                      <Radio.Option
                        id="precipitation"
                        label="Chuva (mm)"
                        checked={true}
                        bottom={10}
                      />
                      <Radio.Option
                        id="temperature"
                        label="Temperatura (°C)"
                        bottom={10}
                      />
                      <Radio.Option
                        id="humidity"
                        label="Umidade (%)"
                        bottom={10}
                      />
                    </Radio>
                  </Control>

                  <Text
                    size={Text.size.twelve}
                    weight={Text.weight.bold}
                    bottom={5}
                  >
                    Estações Meteorológicas
                  </Text>

                  {Object.values(organs).map(({ id, label }) => (
                    <Checkbox
                      key={v4()}
                      id={id}
                      labelAlign={Checkbox.labelAlign.right}
                      size={Checkbox.size.small}
                      onChange={value => setter(id, value)}
                      checked={getter(id)}
                      label={label}
                      bottom={10}
                    />
                  ))}
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'lightning',
          parent: 'monitoring',
          prevent: ({ getter }) => !getter('toggleLightning'),
          onPrevent: () => toOpenToast(),
          type: Type.Group,
          title: ({ getter, setter }) => (
            <Interable>
              <Switch
                id="toggleLightning"
                label="Raios"
                labelAlign="right"
                size={Switch.size.large}
                active={getter('toggleLightning')}
                onChange={() =>
                  setter('toggleLightning', !getter('toggleLightning'))
                }
              />
            </Interable>
          ),
          children: [
            {
              id: 'lightningContent',
              type: Type.Item,
              parent: 'lightning',
              title: ({ setter }) => (
                <Radio
                  name="type"
                  labelAlign={Radio.labelAlign.right}
                  size={Radio.size.small}
                  orientation={Radio.orientation.column}
                  onChange={value => setter('lightningType', value)}
                >
                  <Radio.Option
                    id="recent"
                    label="Últimos 5 minutos"
                    checked={true}
                    bottom={10}
                  />
                  <Radio.Option
                    id="oldest"
                    label="Últimas 2 horas"
                    bottom={10}
                  />
                </Radio>
              ),
              children: []
            }
          ]
        },
        {
          id: 'fires',
          parent: 'monitoring',
          prevent: () => true,
          type: Type.Group,
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="toggleFires"
                  label="Queimadas"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('toggleFires')}
                  onChange={() => setter('toggleFires', !getter('toggleFires'))}
                />
              </>
            </Interable>
          ),
          children: []
        },
        {
          id: 'forecastsLabel',
          parent: 'monitoring',
          title: () => (
            <Text
              weight={Text.weight.bold}
              size={Text.size.ten}
              color={text.quaternary}
            >
              PREVISÕES
            </Text>
          ),
          type: Type.Item,
          children: []
        },
        {
          id: 'forecasts',
          parent: 'monitoring',
          prevent: ({ getter }) => !getter('toggleForecasts'),
          onPrevent: () => toOpenToast(),
          title: ({ getter, setter }) => (
            <Interable>
              <>
                <Switch
                  id="toggleForecasts"
                  label="Previsões WRF"
                  labelAlign="right"
                  size={Switch.size.large}
                  active={getter('toggleForecasts')}
                  onChange={() =>
                    setter('toggleForecasts', !getter('toggleForecasts'))
                  }
                />
              </>
            </Interable>
          ),
          type: Type.Group,
          children: [
            {
              id: 'forecastsContent',
              type: Type.Item,
              parent: 'forecasts',
              title: ({ getter, setter }) => (
                <>
                  <Control>
                    <Range
                      label="Opacidade"
                      bottom={25}
                      id="forecastsOpacity"
                      value={getter('forecastsOpacity')}
                      onChange={value => setter('forecastsOpacity', value)}
                    />
                  </Control>

                  {forecasts.map(({ id, name, info }) => (
                    <Container key={id} bottom={10}>
                      <Switch
                        id={id}
                        label={name}
                        labelAlign={Switch.labelAlign.right}
                        active={getter(id)}
                        onChange={() => setter(id, !getter(id))}
                        right={10}
                      />
                      {info && (
                        <Tooltip
                          size={Tooltip.size.small}
                          header={({ color }) => (
                            <Text color={color}>{name}</Text>
                          )}
                          body={({ color }) => (
                            <Text color={color}>{info}</Text>
                          )}
                        >
                          <Icon
                            name="info"
                            width={15}
                            height={15}
                            color={text.quaternary}
                          />
                        </Tooltip>
                      )}
                    </Container>
                  ))}
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'forecast',
          parent: 'monitoring',
          type: Type.Group,
          onBack: () => history.push('/'),
          onClick: () => history.push('/forecasts'),
          title: ({ details }) => (
            <Title>
              <Icon
                name="barChart"
                right={10}
                left={5}
                width={20}
                height={20}
                color={details}
              />
              <Text size={Text.size.fourteen} weight={Text.weight.light}>
                Previsões Pontuais
              </Text>
            </Title>
          ),
          children: [
            {
              id: 'forecastContent',
              parent: 'forecast',
              type: Type.Item,
              title: ({ setter, getter }) => (
                <>
                  <Text
                    top={15}
                    bottom={15}
                    size={Text.size.fourteen}
                    weight={Text.weight.bold}
                  >
                    Tipos de previsões
                  </Text>

                  <Radio
                    name="type"
                    labelAlign={Radio.labelAlign.right}
                    size={Radio.size.small}
                    orientation={Radio.orientation.column}
                    onChange={value => setter('forecastType', value)}
                  >
                    <Radio.Option
                      data-testid="hourly"
                      id="hourly"
                      label="Avisos (72 horas)"
                      bottom={15}
                      checked={false}
                    />
                    <Radio.Option
                      data-testid="map"
                      id="map"
                      label="Avisos 72 horas (Mapas)"
                      bottom={15}
                      checked={true}
                    />
                    <Radio.Option
                      data-testid="period"
                      id="period"
                      label="Por período (3 dias)"
                      checked={false}
                      bottom={15}
                    />
                  </Radio>

                  <Separator />

                  {getter('forecastType') !== 'map' &&
                    <Text
                      data-testid="typeTitle"
                      top={15}
                      bottom={15}
                      size={Text.size.fourteen}
                      weight={Text.weight.bold}
                    >
                      {getter('isAutocompleteVisible')
                        ? 'Buscar por ponto de interesse ou cidade'
                        : 'Segmentos'}
                    </Text>
                  }
                  

                  {getter('isAutocompleteVisible') && getter('forecastType') !== 'map' && (
                    <Autocomplete
                      data-testid="autocomplete"
                      full={true}
                      onChange={value => setter('locality', value)}
                      categories={fullyCategories}
                      options={getter('citiesAndPoints')}
                    />
                  )}

                  {!getter('isAutocompleteVisible') && getter('forecastType') !== 'map' &&
                    Object.values(filters).map(({ id, name }) => (
                      <Checkbox
                        data-testid={`filter-${name}`}
                        key={v4()}
                        id={id}
                        labelAlign={Checkbox.labelAlign.right}
                        size={Checkbox.size.small}
                        onChange={value => setter(id, value)}
                        checked={getter(id)}
                        label={name}
                        bottom={10}
                      />
                    ))}
                </>
              ),
              children: []
            }
          ]
        },
        {
          id: 'reports',
          parent: 'monitoring',
          type: Type.Group,
          onBack: () => history.push('/'),
          onClick: () => history.push('/reports'),
          title: ({ details }) => (
            <Title>
              <Icon
                name="newsletter"
                right={10}
                left={5}
                width={20}
                height={20}
                color={details}
              />
              <Text size={Text.size.fourteen} weight={Text.weight.light}>
                Boletins
              </Text>
            </Title>
          ),
          children: []
        }
      ]
    }
  ]

  return <>{children({ structure })}</>
}

Structure.propTypes = {
  toOpenToast: PropTypes.func.isRequired
}

export default withRouter(Structure)
