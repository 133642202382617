import { slice, groupBy } from 'ramda'

export const slicer = slice(0, 12)

export const toFixed = value => parseInt(parseFloat(value).toFixed(2))

export const groupByDayAndMonth = structured =>
  groupBy(({ date }) => date.format('YYYY-MM-DD'), structured)

export const degToCompass = deg => {
  const mapping = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW'
  ]

  const val = Math.floor(deg / 22.5 + 0.5)
  return mapping[val % 16]
}

export const toInitial = (value, hour) => {
  const indifferents = {
    '3': 'EN',
    '7': 'CH'
  }

  const initials = {
    '0': 'CC',
    '1': 'PN',
    '2': 'NB',
    '4': 'PI',
    '5': 'NC',
    '6': 'PC'
  }

  const periods = {
    '00': 'night',
    '06': 'day',
    '12': 'day',
    '18': 'night'
  }

  return indifferents[value]
    ? indifferents[value].toLowerCase()
    : `${periods[hour]}${initials[value]}`
}

export const toPeriod = hour => {
  const periods = {
    '00': 'Madrugada',
    '06': 'Manhã',
    '12': 'Tarde',
    '18': 'Noite'
  }

  return periods[hour]
}

export const toDescription = value => {
  const mapping = {
    '0': 'Céu claro',
    '1': 'Poucas nuvens',
    '2': 'Nublado',
    '3': 'Encoberto',
    '4': 'Pancadas isoladas',
    '5': 'Nublado com chuva',
    '6': 'Pancadas de chuva com trovoadas',
    '7': 'Chuvoso'
  }

  return mapping[value]
}
