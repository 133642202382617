import { zipWith, sortByName } from '../utils'

export const normalizeForecast = ({ data, meta }) => {
  const withSort = ({ periods, name, type_segment }) => forecasts =>
    sortByName([
      ...forecasts.map((forecast, forecastIndex) =>
        Object.assign(
          {
            name: name[forecastIndex],
            type_segment: type_segment[forecastIndex]
          },
          ...forecast.map((value, index) => ({
            [periods[index]]: value
          }))
        )
      )
    ])

  const normalize = withSort(data)

  return {
    data: {
      name: data.name,
      periods: data.periods,
      zone: data.zone,
      rain: normalize(data.strong_rain),
      lightning: normalize(data.lightning),
      humidity: normalize(data.rel_humidity),
      slipping: normalize(data.slipping),
      wind: normalize(data.wind_speed)
    },
    meta
  }
}

export const normalizeResume = ({ meta, data }) => {
  const {
    name,
    precipitation_accumulated,
    strong_rain,
    wind_speed,
    humidity,
    slipping,
    lightning,
    type_segment,
    lightnings_24h,
    lightnings_30d
  } = data

  const normalized = zipWith(
    (
      name,
      precipitation,
      rain,
      wind,
      humidity,
      slipping,
      lightning,
      type_segment,
      twentyFourHours,
      thirtyDays
    ) => {
      return Object.assign(
        {},
        {
          name,
          type_segment,
          precipitation,
          lastLightnings: {
            twentyFourHours,
            thirtyDays
          },
          rain: {
            '24h': rain[0],
            '48h': rain[1],
            '72h': rain[2]
          },
          wind: {
            '24h': wind[0],
            '48h': wind[1],
            '72h': wind[2]
          },
          humidity: {
            '24h': humidity[0],
            '48h': humidity[1],
            '72h': humidity[2]
          },
          slipping: {
            '24h': slipping[0],
            '48h': slipping[1],
            '72h': slipping[2]
          },
          lightning: {
            '24h': lightning[0],
            '48h': lightning[1],
            '72h': lightning[2]
          }
        }
      )
    },
    name,
    precipitation_accumulated,
    strong_rain,
    wind_speed,
    humidity,
    slipping,
    lightning,
    type_segment,
    lightnings_24h,
    lightnings_30d
  )

  return {
    data: sortByName(normalized),
    meta
  }
}
