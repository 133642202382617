import { toJSON } from '../utils'
import { Env } from 'tbg/environment'

import { validateFifteenDaysWithPeriods, validateFifteenDays } from './validate'
import {
  normalizePeriodly,
  normalizeMonthlyRecords,
  normalizeDaily
} from './normalize'

const URL = resource => `${Env.getEnv(Env.NIMBUS_URI)}/${resource}`

const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': Env.getEnv(Env.NIMBUS_KEY)
  }
}

export function fetchPeriodly({ latitude, longitude }) {
  return fetch(
    `${URL(
      'forecast/15days'
    )}?latitude=${latitude}&longitude=${longitude}&periods=true`,
    defaultConfig
  )
    .then(toJSON)
    .then(validateFifteenDaysWithPeriods)
    .then(normalizePeriodly)
}

export function fetchDaily({ latitude, longitude }) {
  return fetch(
    `${URL('forecast/15days')}?latitude=${latitude}&longitude=${longitude}`,
    defaultConfig
  )
    .then(toJSON)
    .then(validateFifteenDays)
    .then(normalizeDaily)
}

export function fetchMonthlyRecords({ latitude, longitude }) {
  return fetch(
    `${URL(
      'observed/monthly'
    )}?latitude=${latitude}&longitude=${longitude}&current=true`,
    defaultConfig
  )
    .then(toJSON)
    .then(normalizeMonthlyRecords)
}
