import { Env } from 'tbg/environment'
import { toJSON } from '../utils'
import { normalizeResume, normalizeForecast } from './normalize'

import { validateGeoResume } from './validate'

const URI = resource => `${Env.getEnv(Env.BROLY_URI)}/reports/${resource}`

const defaultConfig = {
  headers: {
    'x-api-key': Env.getEnv(Env.BROLY_KEY)
  }
}

export const fetchGeoResume = async () => {
  try {
    const fetched = await fetch(URI('resume?type=geojson'), defaultConfig)

    if (!fetched.ok) {
      throw new Error(status)
    }

    const response = await toJSON(fetched)

    if (!validateGeoResume(response)) {
      throw new Error('Invalid schema.')
    }

    return response
  } catch (error) {
    throw new Error(error)
  }
}

export function fetchResume() {
  return fetch('https://api.mocki.io/v1/36331698', defaultConfig)
    .then(toJSON)
    .then(normalizeResume)
}

export function fetchHourly() {
  return fetch('https://api.mocki.io/v1/36cfc7e5', defaultConfig)
    .then(toJSON)
    .then(normalizeForecast)
}
