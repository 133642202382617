import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'react-leaflet'
import FullscreenControl from 'react-leaflet-fullscreen'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import L from 'leaflet'

import 'react-leaflet-fullscreen/dist/styles.css'

import { ChooseLayer } from 'tbg/components'

const Container = styled(Map)`
  width: 100%;
  height: 100%;
  position: relative;

  .leaflet-bar a {
    transition: all 0.2s linear;

    &:hover {
      background-color: ${prop('theme.colors.blue.fifty')};
    }
  }

  .icon {
    border: none;
    background: transparent;
  }

  .leaflet-popup-content-wrapper {
    background: transparent;
    box-shadow: none;
  }

  .leaflet-popup-content {
    margin: 0;
  }

  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-left .leaflet-control {
    margin-left: 17px;
    flex-direction: column;
  }

  .leaflet-top .leaflet-control {
    margin-top: 17px;
  }

  .leaflet-control-zoom {
    border: none;
    display: flex;

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    .leaflet-control-zoom-fullscreen {
      border-radius: ${prop('theme.border.radius.fifty')} !important;
      border: none;
      width: 35px;
      height: 35px;
      line-height: 32px;
      color: ${prop('theme.colors.ebony.zero')};

      &:hover {
        color: ${prop('theme.colors.white.hundred')};
      }
    }

    .leaflet-control-zoom-out,
    .leaflet-control-zoom-fullscreen {
      margin-top: 8px;
    }

    a:last-child {
      background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.60976 9.46341H0.658537V14.3415H5.53659V12.3902H2.60976V9.46341ZM0.658508 5.56091H2.60973V2.63408H5.53656V0.682861H0.658508V5.56091ZM12.3659 12.3902H9.43902V14.3415H14.3171V9.46341H12.3659V12.3902ZM9.439020.682927V2.63415H12.3659V5.56098H14.3171V0.682927H9.43902Z" fill="black"/></svg>');
      background-size: 14px 14px;

      &:hover {
        background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14 " viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.60976 9.46341H0.658537V14.3415H5.53659V12.3902H2.60976V9.46341ZM0.658508 5.56091H2.60973V2.63408H5.53656V0.682861H0.658508V5.56091ZM12.3659 12.3902H9.43902V14.3415H14.3171V9.46341H12.3659V12.3902ZM9.439020.682927V2.63415H12.3659V5.56098H14.3171V0.682927H9.43902Z" fill="white"/></svg>');
      }
    }
  }
`

const center = {
  lat: -23.7881,
  lng: -46.5204
}

const corner1 = L.latLng(-24.8608, -48.571)
const corner2 = L.latLng(-21.4948, -44.908)
const maxBounds = L.latLngBounds(corner1, corner2)

export const Mapeable = memo(
  ({ children, onZoom, onMoveEnd, onResizeEnd, mapRef }) => {
    return (
      <>
        <Container
          ref={mapRef}
          center={center}
          zoom={11}
          minZoom={11}
          maxBounds={maxBounds}
          attributionControl
          zoomControl
          scrollWheelZoom
          dragging
          easeLinearity={0.35}
          trackResize
          onZoom={onZoom}
          onMoveEnd={onMoveEnd}
          onResizeEnd={onResizeEnd}
        >
          <ChooseLayer />
          <FullscreenControl position="topleft" />

          {children}
        </Container>
      </>
    )
  }
)

Mapeable.defaultProps = {
  onZoom: () => {},
  onMoveEnd: () => {},
  onResizeEnd: () => {}
}

Mapeable.propTypes = {
  onZoom: PropTypes.func,
  onMoveEnd: PropTypes.func,
  onResizeEnd: PropTypes.func
}
