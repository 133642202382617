const PRODUCTION = 'production'

export const Env = {
  WS_URL: 'WS_URL',

  NIMBUS_URI: 'NIMBUS_URI',
  NIMBUS_KEY: 'NIMBUS_KEY',

  IMAGERY_URL: 'IMAGERY_URL',
  IMAGERY_KEY: 'IMAGERY_KEY',

  BROLY_URI: 'BROLY_URI',
  BROLY_KEY: 'BROLY_KEY',

  SENTRY: 'SENTRY',

  isProduction: () => process.env.ENV === PRODUCTION,

  getEnv: service => {
    const envs = {
      production: {
        WS_URL: 'wss://nimbus-wss.somar.io',

        NIMBUS_URI: 'https://nimbus.somar.io',
        NIMBUS_KEY: 'iEubhBj6na8bXqQa5RZIO89WM6K8AKfU7qTfJq2u',

        IMAGERY_URL: 'https://imagery.somar.io',
        IMAGERY_KEY: 'f02Nu7iXCE3p53DJsjbWS76jor3iUwe51xn6I7Za',

        BROLY_URI: 'https://broly.somar.io',
        BROLY_KEY: 'lV8YIv52Y64kAu6YDFwui3wB4UFlsx8q5w5Grhvd',

        SENTRY: 'https://bcf1d4fd039c4377a6791a0cb442fe9c@sentry.io/1802888'
      },
      development: {
        WS_URL: 'wss://dev.nimbus-wss.somar.io',

        NIMBUS_URI: 'https://dev.nimbus.somar.io',
        NIMBUS_KEY: 'ilfrD9Qzbl5DACoM2kRNLaLaAtT3HAWF80tSyHGW',

        IMAGERY_URL: 'https://imagery.somar.io',
        IMAGERY_KEY: 'f02Nu7iXCE3p53DJsjbWS76jor3iUwe51xn6I7Za',

        BROLY_URI: 'https://dev.broly.somar.io',
        BROLY_KEY: 'lV8YIv52Y64kAu6YDFwui3wB4UFlsx8q5w5Grhvd'
      }
    }

    return envs[process.env.ENV][service]
  }
}
