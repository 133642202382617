import { equals, not, curry } from 'ramda'

export const dispatcher = operations => {
  operations.forEach(operation => {
    const [newest, oldest, callable] = operation

    not(equals(newest, oldest)) && callable(newest)
  })
}

export const random = curry((min, max) => {
  let range = max - min
  return Math.floor(Math.random() * range + min)
})